import {API} from 'aws-amplify';
const apiName = "sandbox";

export function getSandbox() {
  const path = "/sandbox";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // OPTIONAL
      name: "param",
    },
  };

  return API.get(apiName, path, myInit);
}
export function getAuth() {
  const path = "/sandbox/auth";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // OPTIONAL
      name: "getMyAuth",
    },
  };

  return API.get(apiName, path, myInit);
}
